<template>
    <div class="single-banner" v-if="banners.length">
        <swiper class="swiper" :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in banners" :key="item.id" :data-slider-id="item.id" class="px-1 px-lg-0">
                <component :is="item.link ? 'a' : 'div'" v-bind="item.link ? {href: item.link, target: item.target} : ''" @click.prevent="item.link ? url_redirect(item.link, item.target) : ''" class="d-block w-100 mx-auto" style="max-width: 1920px">
                    <div class="bg-image"></div>
                    <div class="aspect-ratio" :style="boxStyle">
                        <div class="aspect-content">
                            <picture>
                                <source :srcset="image_cache(item.file, item.file_cache1920)" media="(min-width: 768px)" />
<!--                                <source :srcset="image_cache(item.file, item.file_cache1920)" media="(min-width: 992px)" />-->
<!--                                <source :srcset="image_cache(item.file, item.file_cache1920)" media="(min-width: 768px)" />-->
<!--                                <source :srcset="image_cache(item.file, item.file_cache1920)" media="(min-width: 576px)" />-->
                                <img :src="image_cache(item.file, item.file_cache790)" width="1920" height="640" :alt="item.name" :title="item.name" class="object-fit-cover" :loading="index ? 'lazy' : 'eager'"/>
                            </picture>
                        </div>
                    </div>
                </component>
            </swiper-slide>
            <div class="swiper-pagination d-none d-lg-block" slot="pagination"></div>
            <div class="swiper-button-prev d-none d-lg-flex" slot="button-prev">
                <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M14 5l-7 7 7 7"/>
                </svg>
            </div>
            <div class="swiper-button-next d-none d-lg-flex" slot="button-next">
                <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M10 5l7 7-7 7"/>
                </svg>
            </div>
        </swiper>
    </div>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    import utils from "~/mixins/utils";

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        mixins: [
            utils
        ],
        props: {
            banners: {
                default: () => [],
                type: Array
            },
            size: {
                type: String, Number,
                default: ''
            },
            loading: {
                type: String,
                default: ''
            }
        },
        created() {
            if (this.banners.length) {
                const link = {
                    rel: 'preload',
                    as: 'image',
                    href: this.image_cache(this.banners[0].file, this.$device.isMobile ? this.banners[0].file_cache790 : this.banners[0].file_cache1920),
                };
                this.$nuxt.$set(this.$nuxt.$options.head, 'link', [
                    ...(this.$nuxt.$options.head.link || []),
                    link,
                ]);
                this.swiperOption.autoplay = this.banners.length > 1 ? { disableOnInteraction: false, pauseOnMouseEnter: true } : false
            }
            if (this.$wdconfig.modules.banner.mobile_aspect_ratio) {
                let padding = this.calculatePadding(this.$wdconfig.modules.banner.mobile_aspect_ratio)
                if (padding) {
                    this.boxStyle['--banner-mobile-padding-top'] = padding
                }
            }
            if (this.$wdconfig.modules.banner.desktop_aspect_ratio) {
                let padding = this.calculatePadding(this.$wdconfig.modules.banner.desktop_aspect_ratio)
                if (padding) {
                    this.boxStyle['--banner-desktop-padding-top'] = padding
                }
            }
        },
        data() {
            return {
                boxStyle: {},
                swiperOption: {
                    // autoplay: {
                    //     disableOnInteraction: false,
                    //     pauseOnMouseEnter: true,
                    // },
                    loop: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        },
        methods: {
            calculatePadding(ratio) {
                if (ratio) {
                    const [width, height] = ratio.split(':').map(Number);
                    if (width > 0 && height > 0) {
                        return (height / width) * 100 + '%';
                    }
                }
                return 0;
            }
        }
    }
</script>
